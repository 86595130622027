import React from 'react';
import styled from 'styled-components/macro';

const BlockSpinner = () => {
  return (
    <Box>
      <div className="absolute">
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M49.4413 25.2244C49.3788 21.9598 48.6704 18.7057 47.3621 15.7286C46.0579 12.7494 44.1663 10.0515 41.8433 7.82232C39.5204 5.59316 36.7683 3.82649 33.7871 2.66399C30.8079 1.49524 27.6059 0.936906 24.4413 1.00566C21.2767 1.06816 18.1267 1.75566 15.2454 3.02441C12.3621 4.28899 9.7496 6.12441 7.59335 8.37441C5.4371 10.6244 3.72877 13.2911 2.60377 16.1765C1.4746 19.0598 0.9371 22.1598 1.00585 25.2223C1.06835 28.2869 1.7371 31.3327 2.96627 34.1182C4.19127 36.9057 5.96835 39.4327 8.14752 41.5182C10.3267 43.6036 12.9058 45.2536 15.6975 46.339C18.4871 47.4286 21.4809 47.9452 24.4454 47.8786C27.41 47.8161 30.3517 47.1682 33.0434 45.9786C35.7371 44.7932 38.1767 43.0744 40.1892 40.9661C42.2017 38.8577 43.7954 36.364 44.8413 33.6702C45.4767 32.0369 45.91 30.3307 46.1371 28.6015C46.1975 28.6057 46.26 28.6077 46.3204 28.6077C48.0454 28.6077 49.4454 27.2077 49.4454 25.4827C49.4454 25.3952 49.4413 25.3077 49.4329 25.2223H49.4454L49.4413 25.2244ZM44.4767 33.5223C43.3308 36.1202 41.6704 38.4744 39.635 40.4161C37.5996 42.3577 35.1913 43.8911 32.5913 44.8973C29.9913 45.9077 27.2059 46.3848 24.4433 46.3182C21.6788 46.2557 18.9454 45.6473 16.4433 44.5369C13.9413 43.4307 11.6746 41.8265 9.80377 39.864C7.93293 37.9015 6.45793 35.5786 5.49127 33.0744C4.52043 30.5702 4.06418 27.889 4.13085 25.2244C4.19543 22.5598 4.78085 19.9307 5.85168 17.5244C6.91835 15.1182 8.46418 12.9369 10.3558 11.1369C12.2475 9.3369 14.4829 7.92024 16.8933 6.99316C19.3017 6.06191 21.8808 5.62649 24.4433 5.69316C27.0058 5.75774 29.5329 6.32441 31.8433 7.35566C34.1558 8.38274 36.2496 9.87024 37.9767 11.6911C39.7038 13.5119 41.0621 15.6598 41.9517 17.9744C42.8433 20.289 43.26 22.7619 43.1933 25.2244H43.2058C43.1996 25.3098 43.1933 25.3973 43.1933 25.4848C43.1933 27.0973 44.4142 28.4223 45.9809 28.5911C45.6767 30.2911 45.1746 31.9515 44.4788 33.5223H44.4767Z" fill="#0074D9"/>
        </svg>
      </div>
    </Box>
  )
}

export default BlockSpinner;

const Box = styled.div.attrs({ className: 'spinner absolute' })`
  width: 100%;
  height: 100%;

  > div {
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
  }

  svg {
    animation: rotate 1.4s linear infinite;
    fill: currentColor;
  }

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`
