import styled from 'styled-components/macro';
import { BreakpointUpMedium } from '../../styles/Breakpoints';

export const Container = styled.div.attrs({ className: 'sa__container'})`
  width: 100%;

  ${BreakpointUpMedium} {
    max-width: 767px;
    margin-left: auto;
    margin-right: auto;
  }
`

export const Content = styled.div.attrs({ className: 'sa__content relative'})`
  padding-left: 20px;
  padding-right: 20px;
  min-height: 400px;

  ${BreakpointUpMedium} {
    padding-left: 30px;
    padding-right: 30px;
  }
`

export const Group = styled.div.attrs({ className: 'sa__group relative'})`
  margin-bottom: 15px;

  &.mb-0 {
    margin-bottom: 0;
  }
`

export const FormBox = styled.div.attrs({ className: 'sa__formbox relative'})`
  padding: 20px 20px;
  background-color: var(--form-bg);

  label {
    font-size: 14px;
    color: #6B7767;
    margin-bottom: 5px;
  }
`

export const CenterMobile = styled.div.attrs({ className: 'flex flex-column items-center' })``;

export const CMElm = styled.div`
  flex-basis: 100%;
  width: 100%;
`;
