import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from './store';
import App from './App';
import './styles/global.scss';

const widgetDivs = document.querySelectorAll('.fyynvideo');

widgetDivs.forEach((div) => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App initid={div.dataset.initid}/>
      </Provider>
    </React.StrictMode>,
    div
  );
});
