import React from 'react';
import styled from 'styled-components/macro';
import { BreakpointUpSmall } from '../../styles/Breakpoints';

const SubHeading = ({ children, align, mb, mt }) => {
  return (
    <Text
      align={align}
      mt={mt}
      mb={mb}
    >
      {children}
    </Text>
  )
}

export default SubHeading;

const Text = styled.h3`
  font-size: 17px;
  color: var(--muted);

  ${({ align }) => align &&`
    text-align: center;
  `}

  ${({ mt }) => mt &&`
    margin-top: ${mt}px;
  `}

  ${({ mb }) => mb &&`
    margin-bottom: ${mb}px;
  `}

  ${BreakpointUpSmall} {
    font-size: 18px;
  }
`;
