import React, { useEffect, useState, useRef, useCallback } from 'react';
import { DialogOverlay, DialogContent } from "@reach/dialog";
import { nanoid } from '@reduxjs/toolkit';
import styled from 'styled-components/macro';
import { useSelector, useDispatch} from 'react-redux';

import axios from '../services/api/axios';
import {
  Container,
  Content,
  CenterMobile,
  Group,
  CMElm
} from '../components/containers/Container';
import Para from '../components/para/Para';
import SubHeading from '../components/heading/SubHeading';
import BlockSpinner from '../components/spinner/BlockSpinner';
import InlineSpinner from '../components/spinner/InlineSpinner';
import Progress from '../components/progress/Progress';
import CountDown from '../components/block/CountDown';
import { createAlert } from '../services/slices/alertSlice';
import { useIsMounted } from '../hooks/useIsMounted';

import DList from '../utils/DList';
import { fetchConstraints, saveConstraits } from '../services/slices/constraintsSlice';

var MediaStreamRecorder = require('msr');

export default function Steps() {

  // hooks
  const isMounted = useIsMounted();
  const dispatch = useDispatch();

  // redux state
  const {status, videoInterview, settings } = useSelector(state => state.root);

  const maxAnswerTime = videoInterview && videoInterview.maxAnswerTime;
  const maxAnswerTimeText = videoInterview && videoInterview.maxAnswerTimeText;

  const mediaPostUrl = videoInterview && videoInterview.post.mediaPostUrl;
  const SuccessText = videoInterview && videoInterview.recordSuccessText;
  const nextUrl = videoInterview && videoInterview.nextUrl;
  const recordBtnText = videoInterview && videoInterview.recordBtnText;
  const waitingBtnText = videoInterview && videoInterview.finished.waitingBtn.text;

  const continueBtnText = videoInterview && videoInterview.finished.nextBtn.text;
  const recordAgainBtnText = videoInterview && videoInterview.finished.againBtn.text;
  const titleText = videoInterview && videoInterview.finished.titletext;
  const workingText = videoInterview && videoInterview.finished.workingtext;
  const stopBtnText = videoInterview && videoInterview.stopBtnText;

  const sessionId = settings && settings.sessionId;
  const userId = settings && settings.userId;

  // redux constraints state
  const { constraints } = useSelector(state => state);

  const videoRef = useRef(null);
  const video2Ref = useRef(null);
  const [recordPreseed, setRecordPreseed] = useState(false);
  const [recordDone, setRecordDone] = useState(false);
  const [intervalID, setIntervalID] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [beforeStartPressed, setBeforeStartPressed] = useState(false);
  const [splashCount, setSplashCount] = useState(3);
  const [uploadState, setUploadState] = useState(false);

  const [count, setCount] = useState(0);
  const [resCount, setResCount] = useState(0);
  const [recordingId, setRecordingId] = useState('');
  const [isClicable, setIsClicable] = useState(false);
  const [vcodec, setVcodec] = useState('video/webm;codecs="vp8,opus"');
  const [mergedAssets, setMergedAssets] = useState(null);
  const [streamValue, setStreamValue] = useState(false);
  const [blobNum, setBlobNum] = useState([]);
  const [overconstrained, setOverconstrained] = useState(false);
  let firstPoster = null

  const nextDisabled = uploadState ? '' : 'disabled';

  const formatTime = val => `0${Math.floor(val)}`.slice(-2);

  const displayMinutes = useCallback((seconds) => {
    return formatTime((seconds % 3600) / 60);
  }, []);

  const displaySeconds = useCallback((seconds) => {
    return formatTime(seconds % 60);
  }, []);

  const [timerSeconds, setTimerSeconds] = useState('');
  const [timerMinutes, setTimerMinutes] = useState('');

  const RecordTimeMiliSeconds =   maxAnswerTime * 1000;

  function captureUserMedia(constraint, successCallback, errorCallback) {
    navigator.mediaDevices.getUserMedia(constraint).then(successCallback).catch(errorCallback);
  }

  const beforeStart = () => {
    setBeforeStartPressed(true);
    setRecordPreseed(true);

    var interval = setInterval(() => {
      setSplashCount((prev) => {
        if ( prev > 0) {
          return prev - 1;
        } else {
          clearInterval(interval)
        }
      })
    }, 1000);

    const timeOut = setTimeout(() => {
      startRecording();
      clearTimeout(timeOut);
    }, 3000)
  }

  const startRecording = async () => {

    if (constraints.status === 'success' && !overconstrained) {
      captureUserMedia(constraints.config, onMediaSuccess, onMediaError);
    } else {
      captureUserMedia({ audio: true, video: true }, onMediaSuccess, onMediaError);
    }

    var countDown = new Date();
    countDown.setSeconds(countDown.getSeconds() + maxAnswerTime);

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDown - now;

      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if ( distance < 100) {
        clearInterval(interval);
      } else {
        setTimerMinutes(formatTime(minutes))
        setTimerSeconds(formatTime(seconds));
      }
    }, 1000);
    setIntervalID(interval)

    const timeOut = setTimeout(() => {
      stopRecording();
      setRecordDone(true);
      clearTimeout(timeOut);
    }, RecordTimeMiliSeconds)
    setTimeoutId(timeOut)
  }

  const stopRecording = () => {
    Steps.mediaRecorder.stop();

    Steps.localStream.getTracks().forEach(track => {
      Steps.localStream.removeTrack(track);
      track.stop();
    });
  }

  const manualStop = () => {
    clearInterval(intervalID);
    clearTimeout(timeoutId)
    setRecordDone(true);
    setTimerMinutes(displayMinutes(maxAnswerTime))
    setTimerSeconds(displaySeconds(maxAnswerTime))
    Steps.mediaRecorder.stop();

    Steps.localStream.getTracks().forEach(track => {
      Steps.localStream.removeTrack(track);
      track.enabled = false
      track.stop();
    });
  }

  const doItAgain = () => {
    setRecordDone(false);
    setRecordPreseed(false);
    setCount(0);
    setResCount(0);
    setUploadState(false);
    // setSendOnce(false)
    setMergedAssets(null);
    setSplashCount(3);
    setBeforeStartPressed(false);
    video2Ref.current.poster = '';
    video2Ref.current.src = '';
    video2Ref.current.load();
    firstPoster = null;
    setStreamValue(false);
    setIsClicable(false);
  }

  const continueUrl = () => {
    const url = `${nextUrl}?sessId=${sessionId}&${new Date().getTime()}`
    window.open(url, '_top').focus();
    setIsClicable(true);
  }

  function onMediaSuccess(stream) {
    var mediaRecorder = new MediaStreamRecorder(stream);
    mediaRecorder.mimeType = vcodec
    mediaRecorder.videoWidth = 800;
    mediaRecorder.videoHeight = 480;
    Steps.mediaRecorder = mediaRecorder;
    Steps.localStream = stream;

    const recordId = nanoid(11);
    setRecordingId(recordId)

    setBlobNum([]);
    var countBolb = 0;
    var fileName = '';
    var recordingInterval;
    clearInterval(recordingInterval);


    mediaRecorder.ondataavailable = function(blob) {

      countBolb = countBolb + 1;
      setBlobNum((state) => [...state, countBolb]);
      // setFileCount((p) => p + 1);
      // console.log('countBolb inside ondataavailable', countBolb)

      fileName = `${countBolb}.webm`;

      // create FormData
      var formData = new FormData();
      // formData.append('section', `steps_id`);
      formData.append('sessionId', sessionId);
      formData.append('userId', userId);
      formData.append('recordingId', recordId);
      formData.append('video', fileName);
      formData.append(`video-blob`, blob);

      uploadBlob(formData);
    };

    var timeInterval = 5000
    if (timeInterval) timeInterval = parseInt(timeInterval);
    else timeInterval = 2 * 1000;

    mediaRecorder.start(timeInterval);
  }

  function uploadBlob(formData) {
    setCount((prev) => prev + 1);

    axios.post(mediaPostUrl, formData).then((res) => {
      if (res.data.status === 200) {
        if (res.data.preview) {
          firstPoster = res.data.preview
          if (firstPoster) {
            video2Ref.current.poster = firstPoster
          }
        }
      }

      if (res.data.status === 200) {
        console.log('upload-ended');
        setResCount((prev) => prev + 1);
      }
    })
    .catch(function (err) {
      console.log(err);
    })
  }

  function onMediaError(e) {
    console.error('media error', e);
  }

  const memoizedCallback = useCallback(() => {

    const totalBlobNum = blobNum[blobNum.length - 1];

    if ( count !== 0 && count === resCount) {
      if (totalBlobNum <= resCount) {
        const formData = new FormData();
        formData.append('sessionId', sessionId);
        formData.append('userId', userId);
        formData.append('recordingId', recordingId);
        formData.append('recordingDone', true);

        axios.post(mediaPostUrl, formData).then((res) => {
          if (res.data.status === 200) {
            if (res.data) {
              setMergedAssets(res.data)
              video2Ref.current.src = res.data.previewVideo
              // video2Ref.current.play();
            }
            console.log('Recording Successfully Done!😂');
          }
        }).catch(function (err) {
          console.log(err);
        })
      }
    }
  },[mediaPostUrl, sessionId, userId, recordingId, count, resCount, blobNum]);

  useEffect(() => {
    if (recordDone) {
      memoizedCallback();
    }
  }, [memoizedCallback, recordDone]);

  useEffect(() => {
    if ( count !== 0 && count === resCount && recordDone) {
      setUploadState(true);
    } else {
      setUploadState(false)
    }
  }, [count, resCount, recordDone]);

  useEffect(() => {
    if (status === 'success' && isMounted.current) {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
      setVcodec('video/webm;codecs="vp9, opus"');
    }
  }, [isMounted, status]);

  useEffect(() => {
    setTimerSeconds(displaySeconds(maxAnswerTime));
    setTimerMinutes(displayMinutes(maxAnswerTime));
  }, [maxAnswerTime, displayMinutes, displaySeconds]);

  // Settings audio / video
  const notSupport = !navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices;
  const microPhoneLabel = videoInterview && videoInterview.functionTest.microphoneText;
  const cameraLabel = videoInterview && videoInterview.functionTest.cameraText;
  const noDevicePermissionText = videoInterview && videoInterview.functionTest.noDevicePermissionText;
  const [showDialog, setShowDialog] = useState(false);
  const [settingBool, setSettingBool] = useState(false);

  const open = () => setShowDialog(true);
  const close = () => {
    setShowDialog(false);
    setSettingBool(false);
  }

  const {
    audioValue,
    videoValue,
    videoOptions,
    audioOptions,
    setVideoValue,
    setAudioValue,
  } = DList();

  useEffect(() => {
    dispatch(fetchConstraints(`${userId}`));
  }, [dispatch, userId]);

  const settingsCallBack = () => {
    setSettingBool(true);
    setOverconstrained(false);
  }

  const onChangeAudio = (e) => {
    setAudioValue(e.target.value);
    settingsCallBack();

    const config = {
      userSessionId: userId,
      audio: {
        deviceId: e.target.value ? { exact: e.target.value } : undefined
      },
      video : {
        deviceId: videoValue ? { exact: videoValue } : undefined,
        width: { ideal: 1280 },
        height: {  ideal: 720 },
      },
    }

    dispatch(saveConstraits(config))
  }

  const onChangeVideo = (e) => {
    setVideoValue(e.target.value);
    settingsCallBack();

    const config = {
      userSessionId: userId,
      audio: {
        deviceId: audioValue ? { exact: audioValue } : undefined
      },
      video : {
        deviceId: e.target.value ? { exact: e.target.value } : undefined,
        width: { ideal: 1280 },
        height: {  ideal: 720 },
      },
    }

    dispatch(saveConstraits(config))
  }

  const getStream = useCallback(async () => {
    const currentRef = videoRef.current;

    if (!recordDone) {
      // for now we need this console log re render this call back
      console.log('ready for recording...')
    }

    if (settingBool) {
      const config = {
        userSessionId: userId,
        audio: {
          deviceId: audioValue ? { exact: audioValue } : undefined
        },
        video : {
          deviceId: videoValue ? { exact: videoValue } : undefined,
          width: { ideal: 1280 },
          height: {  ideal: 720 },
        },
      }

      axios.post('/settings/', config).then((res) => {
        if (res.status === 200) {
          console.log('constraints saving successful');
        } else {
          console.warn('saving constraints went wrong 😥');
        }
      })
    }

    if (constraints.status === 'success' && !overconstrained) {
      return navigator.mediaDevices.getUserMedia(constraints.config).then((stream) => {

        if (currentRef) {
          currentRef.srcObject = stream;
        }

        if (streamValue) {
          stream.getTracks().forEach(track => {
            stream.removeTrack(track);
            track.stop();
          });
        }

      }).catch((error) => {
        if (error.name === 'OverconstrainedError' || error.name === 'OverconstrainedError') {
          setOverconstrained(true);
          console.warn(`OverconstrainedError & OverconstrainedError`)
        }
      });
    }

    if (overconstrained) {
      return navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream) => {

        if (currentRef) {
          currentRef.srcObject = stream;
        }

        if (streamValue) {
          stream.getTracks().forEach(track => {
            stream.removeTrack(track);
            track.stop();
          });
        }

      }).catch((error) => {
        console.warn(error)
      });
    }

  }, [streamValue, recordDone, userId, settingBool, audioValue, videoValue, constraints, overconstrained]);


  useEffect(() => {
    const streamErrorCallBack = () => {
      dispatch(
        createAlert({
          message: noDevicePermissionText,
          type: "error"
        })
      )
    }

    (async () => {
      if (notSupport) {
        streamErrorCallBack();
        return;
      } else {
        getStream();
      }
    })();
  }, [notSupport, getStream, dispatch, noDevicePermissionText]);

  return (
    <>
      <Container>
        <Content>
          {
            status === 'loading' ?
            <BlockSpinner /> :
            <div className="ds">

              {!recordDone && (
                <CenterMobile>
                  <CMElm>
                    <TimeBox style={{ height: 90 }}>
                      <TextCol>{maxAnswerTimeText}</TextCol>
                      <TimeCol>
                        <div className="minute-elm">{timerMinutes}</div>
                        <div className="titme-divider">&nbsp;:&nbsp;</div>
                        <div className="second-elm">{timerSeconds}</div>
                      </TimeCol>
                    </TimeBox>

                    <div className="relative" style={{ lineHeight: 0 }}>
                      {recordPreseed && (
                        <svg
                          className="absolute"
                          width="38"
                          height="46"
                          viewBox="0 0 38 46"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            top: 20,
                            right: 20,
                            zIndex: 10,
                          }}
                        >
                          <path fillRule="evenodd" clipRule="evenodd" d="M8.35522 10.5263C8.35522 5.17064 12.6969 0.828979 18.0526 0.828979C23.4083 0.828979 27.75 5.17064 27.75 10.5263C27.75 15.8821 23.4083 20.2237 18.0526 20.2237C12.6969 20.2237 8.35522 15.8821 8.35522 10.5263ZM3.19922 39.7949H5.5332L8.1992 45H11.3438V44.8633L8.2676 39.0527C9.1204 38.6686 9.7552 38.1544 10.1719 37.5098C10.5951 36.8587 10.8066 36.0482 10.8066 35.0781C10.8066 33.7109 10.3509 32.653 9.4395 31.9044C8.528 31.1557 7.2324 30.7812 5.55273 30.7812H0.269531V45H3.19922V39.7949ZM5.5625 37.422H3.19922V33.1544H5.55273C6.34049 33.1544 6.92318 33.3464 7.3008 33.7305C7.6849 34.1147 7.877 34.6419 7.877 35.3125C7.877 35.9701 7.6751 36.4876 7.2715 36.8652C6.86784 37.2364 6.29818 37.422 5.5625 37.422ZM22.5977 38.8379H16.9727V42.6465H23.5742V45H14.043V30.7812H23.5547V33.1544H16.9727V36.543H22.5977V38.8379ZM35.7559 43.877C36.778 42.998 37.3444 41.7936 37.4551 40.2637H34.5254C34.4603 41.1296 34.2031 41.7708 33.7539 42.1875C33.3112 42.5977 32.6374 42.8027 31.7324 42.8027C30.7298 42.8027 29.9941 42.4479 29.5254 41.7383C29.0632 41.0286 28.832 39.9056 28.832 38.3691V37.2949C28.8451 35.8236 29.0924 34.7396 29.5742 34.043C30.0625 33.3398 30.7949 32.9883 31.7715 32.9883C32.6699 32.9883 33.3372 33.1966 33.7734 33.6133C34.2161 34.0234 34.4733 34.6875 34.5449 35.6055H37.4746C37.3184 34.0169 36.7389 32.7832 35.7363 31.9043C34.7337 31.0254 33.4121 30.5859 31.7715 30.5859C30.5866 30.5859 29.5449 30.8659 28.6465 31.4258C27.7546 31.9792 27.0677 32.7767 26.5859 33.8184C26.1042 34.86 25.8633 36.0645 25.8633 37.4316V38.3008C25.8633 40.4427 26.3841 42.1289 27.4258 43.3594C28.474 44.5833 29.9095 45.1953 31.7324 45.1953C33.3991 45.1953 34.7402 44.7559 35.7559 43.877Z" fill="#E41A04"/>
                        </svg>
                      )}
                      <video
                        ref={videoRef}
                        muted
                        autoPlay
                        className="fn__video mobile-flip"
                        poster="https://staffapply.imgix.net/bg_loading.png?dpr=2&auto=compress,format&w=360"
                        style={{
                          width: '100%',
                          borderRadius: 5,
                        }}
                      >
                      </video>

                      {/* <CountDown /> */}
                      {beforeStartPressed && splashCount > 0 && (
                        <CountDown timer={splashCount} />
                      )}

                    </div>
                  </CMElm>
                </CenterMobile>
              )}

              {!recordPreseed && (
                <>
                  <div className="flex items-center" style={{ paddingTop: 25, paddingBottom: 25 }}>
                    <div style={{ flexGrow: 1 }}>
                      <button
                        className="button secondary block w-100"
                        onClick={beforeStart}
                      >
                        {recordBtnText}
                      </button>
                    </div>

                    <div style={{ flexBasis: 50 }} className="flex items-center justify-end">
                      <button
                        style={{
                          padding: '3px 4px',
                          border: '1px solid transparent',
                          backgroundColor: 'transparent',
                          marginRight: -10,
                        }}
                        onClick={open}
                      >
                        <svg height="40" width="40" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                          <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" transform="translate(3 3)">
                            <path d="m7.5.5c.35132769 0 .69661025.02588228 1.03404495.07584411l.50785434 1.53911115c.44544792.12730646.86820077.30839026 1.26078721.53578009l1.4600028-.70360861c.5166435.39719686.9762801.86487779 1.3645249 1.388658l-.7293289 1.44720284c.2201691.39604534.3936959.82158734.5131582 1.2692035l1.5298263.5338186c.0390082.29913986.0591302.60421522.0591302.91399032 0 .35132769-.0258823.69661025-.0758441 1.03404495l-1.5391112.50785434c-.1273064.44544792-.3083902.86820077-.5357801 1.26078721l.7036087 1.4600028c-.3971969.5166435-.8648778.9762801-1.388658 1.3645249l-1.4472029-.7293289c-.39604532.2201691-.82158732.3936959-1.26920348.5131582l-.5338186 1.5298263c-.29913986.0390082-.60421522.0591302-.91399032.0591302-.35132769 0-.69661025-.0258823-1.03404495-.0758441l-.50785434-1.5391112c-.44544792-.1273064-.86820077-.3083902-1.26078723-.5357801l-1.46000277.7036087c-.51664349-.3971969-.97628006-.8648778-1.36452491-1.388658l.72932886-1.4472029c-.2203328-.39633993-.39395403-.82222042-.51342462-1.27020241l-1.52968981-.53381682c-.03892294-.29882066-.05900023-.60356226-.05900023-.91299317 0-.35132769.02588228-.69661025.07584411-1.03404495l1.53911115-.50785434c.12730646-.44544792.30839026-.86820077.53578009-1.26078723l-.70360861-1.46000277c.39719686-.51664349.86487779-.97628006 1.388658-1.36452491l1.44720284.72932886c.39633995-.2203328.82222044-.39395403 1.27020243-.51342462l.53381682-1.52968981c.29882066-.03892294.60356226-.05900023.91299317-.05900023z" strokeWidth=".933"/>
                            <circle cx="7.5" cy="7.5" r="3" />
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>

                </>
              )}

              {recordPreseed && !recordDone && (
                <div style={{ paddingTop: 25, marginBottom: 25 }}>
                  <button
                    className="button block w-100"
                    onClick={manualStop}
                    style={{
                      backgroundColor: recordDone ? '#F71C1C' : '#BA3729',
                      borderColor: recordDone ? '#F71C1C' : '#BA3729',
                      color: 'white',
                    }}
                  >
                    {stopBtnText}
                  </button>
                </div>
              )}

              {recordDone && (
                <div className="flex flex-column justify-center" style={{ height: 90}}>
                  {(count - resCount) !== 0 && (
                    <>
                      <Para align mb={15}>{SuccessText}</Para>
                      <div style={{paddingBottom: 30 }}>
                        <Progress value={ Math.floor((100/count) * resCount)} bgSuccess height={20} />
                      </div>
                    </>
                  )}

                  {!mergedAssets && uploadState && (
                    <SubHeading align="center">{workingText}</SubHeading>
                  )}

                  {mergedAssets && uploadState && (
                    <SubHeading align="center">{titleText}</SubHeading>
                  )}
                </div>
              )}

              {/* preview video fro */}
              <div style={{ position: 'relative', lineHeight: 0}}>
                <video
                  ref={video2Ref}
                  className={recordDone ? 'fn__video': 'fn__video__01'}
                  autoPlay
                  controls
                  style={{
                    width: '100%',
                    borderRadius: 5,
                  }}
                >
                </video>

                {!mergedAssets && recordDone && (
                  <div
                    className="flex items-center justify-center absolute top-0 right-0 bottom-0 left-0"
                    style={{
                      backgroundColor: 'rgba(10, 10, 10, 0.7)',
                      borderRadius: 5,
                    }}
                  >
                    <BlockSpinner />
                  </div>
                )}

              </div>

              {uploadState && (
                <div style={{ paddingTop: 25 }}>
                  {mergedAssets && (
                    <div style={{ paddingBottom: 25 }}>
                      <button
                        onClick={continueUrl}
                        className={`block w-100 button ${nextDisabled}`}
                        disabled={isClicable}
                      >
                      <span className="inline-flex items-center justify-center">
                        {isClicable && <span style={{ display: 'inline-block', paddingRight: 10 }}><InlineSpinner height={16} /></span>}
                        <span>
                          {/* {!isClicable && ({continueBtnText})}
                          {isClicable && ({waitingBtnText})} */}

                          {isClicable ? <>{waitingBtnText}</> : <>{continueBtnText}</>}
                        </span>
                       </span>
                      </button>
                    </div>
                  )}
                  <div style={{ paddingBottom: 25 }}>
                    <button
                      onClick={doItAgain}
                      className={`block w-100 button tertiary ${nextDisabled}`}
                    >
                      {recordAgainBtnText}
                    </button>
                  </div>
                </div>
              )}

            </div>
          }
        </Content>
      </Container>

      <DOverlay
        isOpen={showDialog}
        onDismiss={close}
      >
        <DContent aria-label="settings popup">
          <DHeader>
            <span>Settings</span>
            <BtnClose onClick={close}>
              <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" transform="translate(5 5)">
                  <path d="m10.5 10.5-10-10z"/>
                  <path d="m10.5.5-10 10"/>
                </g>
              </svg>
            </BtnClose>
          </DHeader>

          <div style={{ padding: '16px 20px' }}>
            <Group>
              <label htmlFor="videoSource">{cameraLabel}</label>
              <select
                className="custom-select"
                id="videoSource"
                defaultValue={videoValue}
                onChange={onChangeVideo}
              >
                {videoOptions && videoOptions.flat().map(({ deviceId, label }) => {
                  return <option key={deviceId} value={deviceId}>{label}</option>
                })}
              </select>
            </Group>
            <Group>
              <label htmlFor="audioSource">{microPhoneLabel}</label>
              <select
                className="custom-select"
                id="audioSource"
                defaultValue={audioValue}
                onChange={onChangeAudio}
              >
                {audioOptions && audioOptions.flat().map(({ deviceId, label }) => {
                  return <option key={deviceId} value={deviceId}>{label}</option>
                })}
              </select>
            </Group>
          </div>
        </DContent>
      </DOverlay>
    </>
  )
}

const TimeBox = styled.div.attrs({ className: 'flex items-center justify-end'})`
  width: 100%;
  font-size: 18px;
`;

const TextCol = styled.div`
  font-size: 16px;
  padding-right: 15px;
`;

const TimeCol = styled.div.attrs({ className: 'flex items-center'})``;

const DOverlay = styled(DialogOverlay).attrs({ className: 'fixed top-0 right-0 bottom-0 left-0 flex items-center flex-column'})`
  overflow: auto;
  background-color: rgba(5, 10, 15, 0.7);
`;

const DContent = styled(DialogContent)`
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 90px 0 0 0;
  border-radius: 5px;
  background-color: var(--white);
  outline: none;
  box-shadow: 0px 10px 50px hsla(0, 0%, 0%, 0.33);

  @media (min-width: 768px) {
    max-width: 707px;
    width: 707px;
  }
`;

const DHeader = styled.div.attrs({ className: 'flex items-center justify-between'})`
  padding: 12px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 16px;
  font-weight: medium;
  border-bottom: 1px solid #e5e6e7;
  background-color: #f4f4f4;
`;

const BtnClose = styled.button.attrs({ type: 'button'})`
  padding: 2px;
  display: inline-block;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;

  &:focus {
    outline: none;
  }
`;
