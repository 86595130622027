import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// screens
import Steps from './screens/Steps';

import { loadAppData } from './services/slices/rootSlice';

export default function App({ initid }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAppData(`${initid}`));
  }, [dispatch, initid]);

  return (<Steps />);
}
