import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axios';

const nameSpace = 'constraints';

export const fetchConstraints = createAsyncThunk(
  `${nameSpace}/fetchConstraints`,

  async (userId) => {
    const { data } = await axios.get(`/settings/?userSessionId=${userId}`);
    const { Response: response, Error: error, ...result } = data

    if (response === 'False') {
      throw new Error('No results found')
    }
    return result
  }
)

const constraintsSlice = createSlice({
  name: nameSpace,
  initialState: {
    status: 'success',
    config: {
      audio: true,
      video: true,
    },
  },
  reducers: {
    saveConstraits: (state, action) => {
      const { audio, video } = action.payload;
      Object.assign(state, { status: 'success', config: { audio, video }});
    }
  },
  extraReducers: {
    [fetchConstraints.pending]: (state) => {
      Object.assign(state, { status: 'loading'})
    },
    [fetchConstraints.fulfilled]: (state, action) => {
      const { audio, video } = action.payload;
      Object.assign(state, { status: 'success', config: { audio, video }});
    },
    [fetchConstraints.rejected]: (state) => {
      Object.assign(state, { status: 'failed'})
    }
  }
});

export const { saveConstraits } = constraintsSlice.actions;

export default constraintsSlice.reducer;
