import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axios';

export const loadAppData = createAsyncThunk(
  'root/loadAppData',

  async (sessId) => {
    const { data } = await axios.get(`/startRecord/?sessId=${sessId}`);
    const { Response: response, Error: error, ...result } = data

    if (response === 'False') {
      throw new Error('No results found')
    }
    return result
  }
)

const rootSlice = createSlice({
  name: 'root',
  initialState: {
    status: null,
    settings: null,
    videoInterview: null,
    notFound: null,
    mediaAlert: null
  },
  reducers: {
    // changeLang: (state, action) => {
    //   state.lang = action.payload;
    // },
  },
  extraReducers: {
    [loadAppData.pending]: (state) => {
      Object.assign(state, { status: 'loading'})
    },
    [loadAppData.fulfilled]: (state, action) => {
      const { settings, videoInterview, notFound, mediaAlert} = action.payload;
      state.status = 'success';
      state.settings = settings;
      state.videoInterview = videoInterview;
      state.notFound = notFound;
      state.mediaAlert = mediaAlert;
      // Object.assign(state, {...action.payload, status: 'success'})
    },
    [loadAppData.rejected]: (state) => {
      Object.assign(state, { status: 'failed'})
    }
  }
});

// export const { changeLang } = rootSlice.actions;

export default rootSlice.reducer;
