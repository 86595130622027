import React from 'react';
import styled from 'styled-components/macro';

const Progress = ({ value, mt, mb, showValue = true, bgSuccess, height }) => {
  return (
    <Box
      mt={mt}
      mb={mb}
    >
      <ProgressBox height={height}>
       <Bar
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin="0"
        aria-valuemax="100"
        bgSuccess={bgSuccess}
        style={{ width: `${value}%`}}
       >
       {showValue && `${value}%`}
       </Bar>
      </ProgressBox>
    </Box>
  )
}

export default Progress;

const Box = styled.div`

  ${({ mt }) => mt &&`
    margin-top: ${mt}px;
  `}

  ${({ mb }) => mb &&`
    margin-bottom: ${mb}px;
  `}
`;

const ProgressBox = styled.div`
  display: flex;
  height: 40px;
  overflow: hidden;
  line-height: 0;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem;

  ${({ height }) => height &&`
    height: ${height}px;
    border-radius: 0;
  `}
`;

const Bar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: var(--brand-primary);
  transition: width .6s ease;
  background-image: linear-gradient(45deg ,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-size: 1rem 1rem;
  animation: 1s linear infinite;

  ${({ bgSuccess }) => bgSuccess && `
    background-color: #198754 !important;
  `}
`;


